.cardBody{
    display: grid;
    place-items:  center;
    min-height:  40vh;
    background:  #222;
}

.container{
    position: relative;
    width: 450px;
    height: relative;
}

.container .borders span{
    position: absolute;
    width: 100px;
    height: 100px;
}

.container .borders span:nth-child(1){
    border-left: 2px solid #218bd1;
    border-top: 2px solid #218bd1;
    left: -30px;
    top: -30px;
}

.container .borders span:nth-child(2){
    border-right: 2px solid #218bd1;
    border-top: 2px solid #218bd1;
    right: -30px;
    top: -30px;
}

.container .borders span:nth-child(3){
    border-right: 2px solid #218bd1;
    border-bottom: 2px solid #218bd1;
    right: -30px;
    bottom: -30px;
}

.container .borders span:nth-child(4){
    border-left: 2px solid #218bd1;
    border-bottom: 2px solid #218bd1;
    left: -30px;
    bottom: -30px;
}
.container .box{
    position: relative;
    display: flex;
    width: 400px;
    height: 250px;
    border: 5px solid #444;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -1%);
}

.container .box .pic{
    position: relative;
    width: 130px;
    height: 170px;
    border: 5px solid #444;
    top: 40px;
    left: 30px;
}

.container .box .pic::before {
    content: '';
    position: absolute;
    width: 70px;
    height: 70px;
    border: 5px solid #444;
    border-radius: 50%;
    top: 25px;
    left: 26px;
}

.container .box .pic:after {
    content: '';
    position: absolute;
    width: 108px;
    height: 76px;
    border: 5px solid #444;
    bottom: -5px;
    left: 6px;
    border-radius: 100px 100px 0 0;
}

.container .box .content{
    position: relative;
    width: 200px;
    height: 170px;
    top: 20px;
    left: 40px;
}

.container .box .content span{
    position: absolute;
    width: 90%;
    height: 6px;
    background: #444;
    border-radius: 50px;
    top: calc(var(--i) * 20px);
}

.container .scanner{
    position: absolute;
    width: 3px;
    height: 250px;
    background: #218bd1;
    top: 0;
    animation: anim 3s linear infinite;
}


@keyframes anim{
    0%, 100%{
        left: 0;
    }
    50%{
        left: 100%;
    }
}

.container p{
    position: absolute;
    bottom: -40px;
    color: #218bd1;
    font-size: 17px;
    left: 45%;
    animation: anim2 1s linear infinite;
}

.container p span{
    position: absolute;
    width: 20px;
    height: 20px;
    background: #218bd1;
    border-radius: 50%;
    left: -30px;
    top: 0;
}

@keyframes anim2{
    0%, 100%{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
}